import React from 'react';
import clsx from 'clsx';

export interface ContentBodyProps {
  children: React.ReactNode;
  reverse?: boolean;
}

export default function ContentBody({ children, reverse = false }: ContentBodyProps) {
  return (
    <div
      className={clsx('w-full px-3 sm:px-12 pb-12 sm:pb-0 order-first', {
        'sm:order-first': reverse,
        'sm:order-none': !reverse,
      })}
    >
      {children}
    </div>
  );
}
