import React from 'react';

import Button from '../Button';

export default function Hero() {
  return (
    <div
      className="relative py-16 flex content-center items-center justify-center bg-black"
      style={{ minHeight: 'calc(100vh - 88px)' }}
    >
      <div
        className="absolute top-0 w-full h-full bg-center bg-cover bg-right-top"
        style={{ backgroundImage: "url('/vellu2.jpg')" }}
      >
        <span className="w-full h-full absolute opacity-40 bg-black"></span>
      </div>
      <div className="container relative">
        <div className="items-center flex flex-wrap">
          <div className="w-full md:w-1/2 px-3 sm:px-6">
            <h1
              className="text-blue font-display text-5xl md:text-8xl lg:text-8xl xl:text-9xl"
              style={{ wordBreak: 'break-word' }}
            >
              <span className="px-3 my-1 bg-black whitespace-nowrap tracking-wide leading-tight uppercase">
                Vaihteistot
              </span>
              <br />
              <span className="px-3 my-1 bg-black whitespace-nowrap tracking-wide leading-tight uppercase">
                kuntoon
              </span>
              <br />
              <span className="px-3 my-1 bg-black whitespace-nowrap tracking-wide leading-tight uppercase">
                ammattitaidolla
              </span>
            </h1>

            <p className="text-blue font-display mt-4 mb-6 text-2xl md:text-5xl">
              <span className="px-3 my-1 bg-black whitespace-nowrap tracking-wide leading-tight uppercase">
                Yli 30 vuoden kokemuksella
              </span>
            </p>

            <Button href="#contact">
              <div className="flex flex-row items-center">
                <div className="mr-2">Ota yhteyttä</div>
                <div className="text-white" aria-hidden style={{ width: 24, height: 24 }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
