import React, { ReactNode, ReactElement } from 'react';
import clsx from 'clsx';

import ContentBody from './ContentBody';
import ContentImage from './ContentImage';

export interface ContentProps {
  children: ReactNode;
  reverse?: boolean;
}

export default function Content({ children, reverse = false }: ContentProps) {
  const elements =
    children instanceof Array
      ? children.map((child, index) => React.cloneElement(child as ReactElement, { key: index, reverse }))
      : React.cloneElement(children as ReactElement, { reverse });

  return (
    <section className={clsx('relative py-20', { 'bg-gray-100': reverse })}>
      <div className="container mx-auto px-2 sm:px-4">
        <div className="flex flex-col sm:flex-row justify-center items-center">{elements}</div>
      </div>
    </section>
  );
}

Content.Body = ContentBody;
Content.Image = ContentImage;
