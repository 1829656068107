import React, { ReactNode } from 'react';

import { Box, Grid, Typography } from '@mui/material';

export interface ContactInfoGridItem {
  icon: ReactNode;
  title: string;
  children: ReactNode;
}

export const ContactInfoGridItem = ({ icon, title, children }: ContactInfoGridItem) => (
  <Grid item xs={12} md={3} textAlign="center" px={1}>
    <Box
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="common.white"
      borderRadius="50%"
      width="3rem"
      height="3rem"
    >
      {icon}
    </Box>

    <Typography variant="h3" color="common.white" mt={2}>
      {title}
    </Typography>

    <Box color="grey.400" mt={1}>
      {children}
    </Box>
  </Grid>
);
