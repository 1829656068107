import React, { HTMLProps } from 'react';

export type ListItemProps = HTMLProps<HTMLLIElement>;

export default function ListItem({ children, ...rest }: ListItemProps) {
  return (
    <li className="py-2" {...rest}>
      <div className="flex items-center">
        <div className="mr-2">
          <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="text-md lg:text-lg">{children}</div>
      </div>
    </li>
  );
}
