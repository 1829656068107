import React, { HTMLProps } from 'react';

import ListItem from './ListItem';

export type ListProps = HTMLProps<HTMLUListElement>;

export default function List({ children, ...rest }: ListProps) {
  return (
    <ul className="list-none" {...rest}>
      {children}
    </ul>
  );
}

List.Item = ListItem;
