import React from 'react';
import clsx from 'clsx';

export interface ContentImageProps {
  src: string;
  small?: boolean;
  shadow?: boolean;
  href?: string;
}

export default function ContentImage({ src, small = false, shadow = false, href }: ContentImageProps) {
  const img = (
    <img
      alt=""
      className={clsx('inline-block w-full rounded-md', { 'shadow-md': shadow })}
      src={src}
      style={{ maxWidth: small ? 360 : 480 }}
    />
  );

  return (
    <div className="w-full px-3 text-center">
      {href ? (
        <a className="inline-block" href={href} target="_blank">
          {img}
        </a>
      ) : (
        img
      )}
    </div>
  );
}
