import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { SiteMetadata } from '../../types';

interface Data {
  site: {
    siteMetadata: SiteMetadata;
  };
}

export default function SEO() {
  const data: Data = useStaticQuery(query);
  const { title, description } = data.site.siteMetadata;

  return (
    <Helmet
      meta={[
        { name: 'description', content: description },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        { property: 'og:type', content: 'website' },
      ]}
    />
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
