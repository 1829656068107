import React from 'react';

import { Box, Container, Grid, Link, Stack, Typography } from '@mui/material';

export const Footer = () => (
  <Box component="footer" position="relative" bgcolor="grey.100" py={6}>
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} textAlign="center">
          <Typography variant="h4" mb={[1, 1, 2]}>
            Yritys
          </Typography>

          <Stack gap={1}>
            <Typography variant="body2" color="grey.500">
              Vantaan Erikois-Auto Oy
              <br />
              Y-tunnus 0693506-9
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} md={4} textAlign="center">
          <Typography variant="h4" mb={[1, 1, 2]}>
            Yhteystiedot
          </Typography>

          <Stack gap={1}>
            <Typography variant="body2" color="grey.500">
              Ajanvaraukset
              <br />
              <Link href="tel:09892804">09 892 804</Link>
            </Typography>

            <Typography variant="body2" color="grey.500">
              Tekninen neuvonta
              <br />
              <Link href="tel:060004110">0600 04 110</Link>
              <br />
              (2,75 €/alkava min + pvm/mpm)
            </Typography>

            <Typography variant="body2" color="grey.500">
              <Link href="mailto:vantaan.erikois-auto@elisanet.fi">vantaan.erikois-auto@elisanet.fi</Link>
            </Typography>

            <Typography variant="body2" color="grey.500">
              Piispankyläntie 10
              <br />
              01730 Vantaa
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} md={4} display="flex" flexDirection="column" alignItems="center" textAlign="center">
          <Typography variant="h4" mb={[1, 1, 2]}>
            Seuraa meitä Facebookissa
          </Typography>

          <Stack gap={1}>
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FVantaan-Erikois-Auto-Oy-219938218338940&tabs&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
              width="340"
              height="130"
              style={{ border: 'none', overflow: 'hidden' }}
              scrolling="no"
              frameBorder="0"
              allowFullScreen
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  </Box>
);
