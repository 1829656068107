import React from 'react';

import { AccessTime, Build, Phone, Place } from '@mui/icons-material';
import { Box, Grid, Link, Typography } from '@mui/material';

import { ContactInfoGridItem } from './ContactInfoGridItem';

export const ContactInfoGrid = () => (
  <Grid container mt={6} gap={[5, 5, 0]}>
    <ContactInfoGridItem icon={<Place sx={{ fontSize: 28 }} />} title="Sijainti">
      <Typography variant="body2">
        Piispankyläntie 10
        <br />
        01730 Vantaa
        <br />
        <Link href="https://goo.gl/maps/bw1ZepJ5C3Qo8cDf8" target="_blank">
          Sijainti kartalla &rarr;
        </Link>
      </Typography>
    </ContactInfoGridItem>

    <ContactInfoGridItem icon={<Phone sx={{ fontSize: 28 }} />} title="Ajanvaraukset">
      <Typography variant="body2">
        <Link href="tel:09892804">09 892 804</Link>
        <br />
        <Link href="mailto:vantaan.erikois-auto@elisanet.fi">vantaan.erikois-auto@elisanet.fi</Link>
      </Typography>
    </ContactInfoGridItem>

    <ContactInfoGridItem icon={<Build sx={{ fontSize: 28 }} />} title="Tekninen neuvonta">
      <Typography variant="body2">
        <Link href="tel:060004110">0600 04 110</Link>
        <br />
        2,75 €/alkava min + pvm/mpm
      </Typography>
    </ContactInfoGridItem>

    <ContactInfoGridItem icon={<AccessTime sx={{ fontSize: 28 }} />} title="Olemme avoinna">
      <Box mx="auto" width="200px">
        <Typography component="dl" variant="body2">
          <Grid container spacing={2}>
            <Grid item component="dt" xs={6}>
              Ma - To
            </Grid>
            <Grid item component="dd" xs={6}>
              9-17
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item component="dt" xs={6}>
              Pe
            </Grid>
            <Grid item component="dd" xs={6}>
              9-16
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item component="dt" xs={6}>
              La - Su
            </Grid>
            <Grid item component="dd" xs={6}>
              Suljettu
            </Grid>
          </Grid>
        </Typography>
      </Box>
    </ContactInfoGridItem>
  </Grid>
);
