import React, { useEffect, useCallback, useState } from 'react';
import clsx from 'clsx';

import Button from '../Button';

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  const onScroll = useCallback(() => {
    setIsScrolled(window.pageYOffset > 0);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <header
      className="sticky top-0 z-50 flex justify-center items-center bg-gray-900 text-white shadow-lg'"
      style={{ height: '88px' }}
    >
      <div className="container relative">
        <div className="flex items-center justify-between w-full px-3 sm:px-6">
          <div>
            <img className="w-40 sm:w-52" src="/logo.svg" alt="Vantaan Erikois-Auto Oy" />
          </div>
          <div>
            <Button variant={isScrolled ? 'standard' : 'outlined'} size="small" href="#contact">
              Ota yhteyttä
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
}
