import React from 'react';

export interface TextareaProps {
  id: string;
  label: string;
  required?: boolean;
}

export default function Textarea({ id, label, required = false }: TextareaProps) {
  return (
    <>
      <label className="block text-gray text-sm font-bold mb-2" htmlFor={id}>
        {label}
        {required && <span aria-hidden> *</span>}
      </label>
      <textarea
        className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
        style={{ transition: 'all .15s ease' }}
        id={id}
        name={id}
        required={required}
        rows={4}
      />
    </>
  );
}
