import React from 'react';

import Button from '../Button';
import Input from '../Input';
import Textarea from '../Textarea';

export default function ContactForm() {
  return (
    <section className="relative block pb-24 bg-gray-900">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-center">
          <div className="w-full md:w-10/12 lg:w-6/12">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100">
              <form
                className="flex-auto py-7 px-5 md:py-10 md:px-10"
                name="contact"
                action="/kiitos"
                data-netlify="true"
              >
                <h4 className="text-xl lg:text-2xl font-semibold mb-2">Yhteydenottolomake</h4>

                <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                  Täytä tämä lomake ja palaamme asiaan mahdollisimman pian.
                </p>

                <input type="hidden" name="form-name" value="contact" />

                <div className="relative w-full mb-3 mt-8">
                  <Input id="name" label="Nimi" type="text" required />
                </div>

                <div className="relative w-full mb-3">
                  <Input id="email" label="Sähköposti" type="email" required />
                </div>

                <div className="relative w-full mb-3">
                  <Input id="phone" label="Puhelinnumero" type="tel" />
                </div>

                <div className="relative w-full mb-3">
                  <Input id="text" label="Rekisterinumero" type="text" />
                </div>

                <div className="relative w-full mb-3">
                  <Textarea id="message" label="Viesti" required />
                </div>

                <div className="mt-5">
                  <Button type="submit" size="medium">
                    Lähetä
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
